<template>
  <b-nav-item @click="toggleOperatorMode" v-if="allModesAccess">
    <IconPCP v-if="isNavMenuHidden"
      v-b-tooltip.hover
      :title="$t('PCP Mode')"
    />
    <IconOperator v-else
      v-b-tooltip.hover
      :title="$t('Operator Mode')"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavItem, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import IconOperator from '@/assets/images/pages/operator-mode-icon.svg'
import IconPCP from '@/assets/images/icons/modo-pcp.svg'

export default {
  components: {
    BNavItem,
    VBTooltip,
    IconOperator,
    IconPCP
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data(){
    return {
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
      allModesAccess: JSON.parse(localStorage.getItem('userData')).ability.find(element => element.subject === 'pcp') && JSON.parse(localStorage.getItem('userData')).ability.find(element => element.subject === 'operator')
    }
  },
  created(){
    if (this.$route.name) {
        localStorage.setItem('mode', this.$route.name.split('-')[0] == 'operator' ? 'operator' : 'pcp')
        return this.isNavMenuHidden = this.$route.name.split('-')[0] == 'operator' ? true : false
    }
  },
  methods: {
    toggleOperatorMode(){
      this.isNavMenuHidden = !this.isNavMenuHidden
      return this.$route.name.split('-')[0] == 'operator' ? this.$router.push({name: 'home', params: {prefix: this.prefix}}) : this.$router.push({name: 'operator', params: {prefix: this.prefix}})
    },
  },
  setup() {
    const { isNavMenuHidden } = useAppConfig()

    return { isNavMenuHidden }
  },
}
</script>
