<template>
  <button
    class="navigation-section button"
    @click="click"
  >
    <div class="icon-container">
      <slot name="icon" />
    </div>
    <div>
      <h3>{{ title }}</h3>
      <h6>{{ subtitle }}</h6>
    </div>
  </button>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'icon', 'click'],
}
</script>

<style lang="scss" scoped>
.navigation-section.button {
  gap: 16px;
  background: transparent;
  outline: 0;
  border: 0;
  .icon-container {
    min-width: 50px;
    min-height: 50px;
    border-radius: 6px;
    border: 1px solid #974900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #ffede2;
  }
  &:active {
    background: #ffdbc4;
  }

  h3,
  h6 {
    font-family: "Raleway";
    font-style: normal;
    text-align: left;
  }

  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #4c4541;
    margin: 0;
  }
  h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #998f8a;
    margin: 0;
  }
}
</style>
