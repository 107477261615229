<template>
    <div
        :class="['profile-full', open ? 'open' : '']"
        @click="openProfileOptions"
    >
        <div class="profile">
            <div class="user-data">
                <img v-if="user.photo" :src="user.photo" alt="Profile Image" />
                <AvatarPlaceholder v-else size="38px" :text="picturePlaceholder"/>
                <div class="user">
                    <strong>{{ user.first_name }} {{ user.last_name }}</strong>
                    <span>{{ user.job_role }}</span>
                </div>
            </div>
            <button class="close-btn">
                <ArrowDown
                    :class="['arrow-down-icon', open ? 'flipped' : '']"
                />
            </button>
        </div>
        <transition name="card">
            <div v-if="open" class="profile-options">
                <button
                    v-for="item in items"
                    :key="item.id"
                    class="profile-options-btn"
                    @click="item.click"
                >
                    <div class="icon">
                        <component :is="item.icon" class="button-icon" />
                    </div>
                    <span>{{ $t(item.title) }}</span>
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue'

export default {
    name: "ProfileCard",
    components: {
        ArrowDown: () => import("@core/assets/icons/arrow-down.svg"),
        ExitIcon: () => import("@core/assets/icons/exit-icon.svg"),
        ProfileIcon: () => import("@core/assets/icons/profile-icon.svg"),
        AvatarPlaceholder
    },
    props: {
        user: {
            type: Object,
            required: true,
            default: () => ({
                name: "Rodrigo Faro",
                role: "Desenvolvedor front-end",
                picture: "",
            }),
        },
        items: {
            type: Array,
            required: true,
            default: () => [
                {
                    id: 1,
                    title: "Minha Conta",
                    icon: "icon",
                    click: () => {},
                },
            ],
        },
    },
    data: () => ({
        open: false,
    }),
    methods: {
        openProfileOptions() {
            this.open = !this.open;
        },
        handleRedirectProfile(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        handleSignOut(e) {
            e.preventDefault();
            e.stopPropagation();
        },
    },
    computed: {
        picturePlaceholder() {
            const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? ''
            return `${firstLetter(this.user.first_name)}${firstLetter(this.user.last_name)}`
        }
    }
};
</script>

<style lang="scss">
.card-enter-active,
.card-leave-active {
    transition: all 0.35s, opacity 0.18s;
    max-height: 500px !important;
}
.card-enter,
.card-leave-to {
    opacity: 0;
    max-height: 0px !important;
}
</style>

<style lang="scss" scoped>
.close-btn {
    padding: 0;
    svg {
        fill: #998f8a;
    }
}
.user-data {
    display: flex;
    flex-direction: row;
    gap: 14px;
}
.arrow-down-icon {
    width: 16px;
    height: 16px;
    justify-self: flex-end;
    margin: 0;

    &.flipped {
        transform: rotate(180deg);
    }
}

.profile-full {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;

    min-width: 216.5px;

    border-radius: 6px;
    border: 1px solid #eeeeee;
    user-select: none;
    cursor: pointer;

    padding-bottom: 16px;

    .profile {
        display: flex;
        flex-direction: row;
        gap: 14px;
        padding: 16px 16px 0 16px;
        justify-content: space-between;
    }

    .profile-options {
        display: flex;
        flex-direction: column;
        max-height: 150px;
    }

    &:hover:not(.open) {
        border: 1px solid #974900;
        background-color: #ffede2;
        .close-btn svg {
            path {
                fill: #974900;
            }
        }
    }

    &:active:not(:focus-within) {
        border: 1px solid #e1730b;
        background: rgba(151, 73, 0, 0.16);
        background-color: #ffdbc4;
    }

    img {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 100%;
    }

    .user {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        strong {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #4c4541;
            white-space: nowrap;
            max-width: 114px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #998f8a;
        }
    }

    button {
        cursor: pointer;
        outline: 0;
        background-color: transparent;
        border: 0;

        display: flex;
        align-items: center;
    }
}

.profile-options-btn {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;

    &:hover {
        background-color: #ffede2;
        span {
            color: #974900;
        }
        svg {
            path {
                fill: #974900;
            }
        }
    }

    &:active {
        background-color: #ffdbc4;
    }

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #998f8a;
    }
}
.icon {
    width: 14px;
    height: 14px;
    svg {
        transform: translate(-20%, -20%) scale(0.5833);
        fill: #998f8a;
    }
}
</style>
