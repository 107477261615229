<template>
  <div class="navigation-section">
    <h5>{{ $t('Operator.Navigation.Title') }}</h5>
    <NavigationItem
      :title="$t('Operator.Navigation.Orders.Title')"
      :subtitle="$t('Operator.Navigation.Orders.Subtitle')"
      :click="() => redirect('operator-orders-all')"
    >
      <template v-slot:icon>
        <OrdersButtonIcon />
      </template>
    </NavigationItem>
    <NavigationItem
      :title="$t('Operator.Navigation.BarCode.Title')"
      :subtitle="$t('Operator.Navigation.BarCode.Subtitle')"
      :click="() => redirect('operator-barcode')"
    >
      <template v-slot:icon>
        <BarCodeButtonIcon />
      </template>
    </NavigationItem>

  </div>
</template>

<script>
import OrdersButtonIcon from '@/assets/images/pages/orders-button-icon.svg'
import BarCodeButtonIcon from '@/assets/images/pages/barcode-button-icon.svg'
import NavigationItem from './NavigationItem.vue'

export default {
  components: {
    OrdersButtonIcon,
    BarCodeButtonIcon,
    NavigationItem,
  },
  props: ['closeNavigationMenu'],
  data() {
    return {
    }
  },
  methods: {
    redirect(path) {
      const isSamePage = String(this.$router.currentRoute.name) === String(path)
      if (!isSamePage) {
        this.$router.push({ name: path })
      }
      this.closeNavigationMenu()
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation-section {
  flex: 1;
  height: 100%;
  padding: 16px;
  user-select: none;
  h5 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #4c4541;
    margin-bottom: 16px;
  }
}
</style>
