<template>
    <div id="profile">
        <div v-if="variant === 'short'">
            <Picture id="user-picture" :url="user.photo" :placeholder="picturePlaceholder"/>
            <Tooltip target="user-picture" :text="$t('Menu.Titles.Account')" />
            <Popover
                :items="items"
                target="user-picture"
                :title="title"
                placement="rightbottom"
            />
        </div>

        <div v-else class="user-info__card">
            <Card :user="user" :items="items" />
        </div>
    </div>
</template>

<script>
// Components
import Picture from "./Picture.vue";
import Card from "./Card.vue";
import Popover from "../Popover.vue";
import Tooltip from "../Tooltip.vue";

// Icons
import ProfileIcon from "@core/assets/icons/profile-icon.svg";
import ExitIcon from "@core/assets/icons/exit-icon.svg";

import { mapActions } from "vuex";

const ICONS = {
    ProfileIcon,
    ExitIcon,
};

export default {
    name: "ProfileComponent",
    components: {
        Picture,
        Card,
        Popover,
        Tooltip,
    },
    props: {
        variant: {
            type: String,
            default: "short",
        },
        user: {
            type: Object,
            required: true,
            default: () => ({
                first_name: "Rodrigo",
                last_name: "Faro",
                job_role: "CEO",
                photo: "",
            }),
        },
        title: {
            type: String,
            default: "  ",
            required: true,
        },
    },
    data() {
        var self = this;
        return {
            open: false,
            items: [
                {
                    title: "Menu.Items.Account",
                    icon: ICONS.ProfileIcon,
                    click: () =>
                        (window.location.href = `${
                            this.$myIndLink
                        }${localStorage.getItem("prefix")}/account`),
                },
                {
                    title: "Menu.Items.Exit",
                    icon: ICONS.ExitIcon,
                    click: () => self.logout(),
                },
            ],
        };
    },
    methods: {
        logout() {
            this.$emit("logout");
        },
        redirect(page) {
            if (this.$route.name === page) return;

            this.$router.push({ name: page });
            this.$emit("close");
        },
    },
    computed: {
        picturePlaceholder() {
            const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? ''
            return `${firstLetter(this.user.first_name)}${firstLetter(this.user.last_name)}`
        }
    }
};
</script>

<style lang="scss" scoped>
#profile:has(.user-info__card) {
    width: inherit;
}
</style>
