export default [
  {
    title: 'Orders',
    icon: 'CalendarIcon',
    route: 'order-list',
    action: 'read',
    resource: 'pcp',
  },
  {
    title: 'Production Progress',
    icon: 'TrendingUpIcon',
    route: 'production-progress',
    action: 'read',
    resource: 'pcp',
  },
  {
    
    header: 'Dashboards',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Resource Loading',
        route: 'resource-loading',
        icon: 'BarChart2Icon',
        action: 'read',
        resource: 'pcp',
      },   
      {
        title: 'Schedule Analysis',
        route: 'schedule-analysis',
        icon: 'PieChartIcon',
        action: 'read',
        resource: 'pcp',
      },   
      {
        title: 'OEE',
        route: 'oee',
        icon: 'TrendingUpIcon',
        action: 'read',
        resource: 'pcp',
      },
    ],
  },
  {
    header: 'System Setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'WorkCenters',
        route: 'workcenters',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'pcp',
      },    
      {
        title: 'Classification',
        icon: 'ListIcon',
        action: 'read',
        resource: 'pcp',
        children: [
          {
            title: 'Refuse Status',
            route: 'refuse-status',
            icon: 'ChevronRightIcon',
            action: 'read',
            resource: 'pcp',
          },
          {
            title: 'Resource Status',
            route: 'resource-status',
            icon: 'ChevronRightIcon',
            action: 'read',
            resource: 'pcp',
          },
          
        ],
      },
      {
        title: 'Resources',
        route: 'resources',
        icon: 'SlidersIcon',
        action: 'read',
        resource: 'pcp',
      },
      {
        title: 'Sites',
        route: 'sites',
        icon: 'DatabaseIcon',
        action: 'read',
        resource: 'pcp',
      },   
      
    ]
  },
]